interface UpcomingIconProps {
    className?: string;
}

export default function UpcomingIcon({ className = 'icon icon-upcoming' }: UpcomingIconProps) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
        >
            <path
                d="M7.87468 5.92857V8.21429L10 10M1 3V5.6875H1.54517M15.942 7.5625C15.4806 3.86283 12.3246 1 8.5 1C5.35246 1 2.65777 2.93891 1.54517 5.6875M1.54517 5.6875H4M16 14V11.3125H15.4548M15.4548 11.3125C14.3422 14.0611 11.6475 16 8.5 16C4.67536 16 1.51937 13.1372 1.05803 9.4375M15.4548 11.3125H13"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </svg>
    );
}
