export const defaultOGImage = '/default-avatar.jpeg';
export const multipassPrice = 200;
export const multipassTeamPrice = 160;
export const tagsInMenu = 20;
export const getMultipassFeatures = () => {
    return [
        'Full access to all GitNation remote conferences and exclusive events',
        'Instant access to fresh library of advanced content on React, Vue, Angular, Node, and GraphQL',
        'Live JavaScript hands-on workshops with top trainers',
        'Certification upon event or course completion',
        'Exclusive career advice articles',
        'Transcriptions and talk content extras like slides, QnA and resources',
        'Afterparties with speakers and attendees',
        "Interactive chat in speakers' video rooms and discussion rooms",
    ];
};
export const MULTIPASS_TYPES = ['annual', 'annual-team', 'bi-annual', 'quarterly', 'lifetime'];
export const MULTIPASS_PRICES = {
    annual: 200,
    'annual-team': 160,
    'bi-annual': 140,
    quarterly: 80,
    lifetime: 599,
};
export const LIFETIME_MULTIPASS_LINK = 'https://ti.to/gitnation/conference-multipass';
export const LIFETIME_MULTIPASS_END_DATE = new Date('2100-01-01 00:00:00');

export const SHOW_EVENT_WATCH_BUTTON_DAYS_BEFORE = 1;

// IMPORTANT CACHE NOTES
// If Next.js app in production returns a response that contains "stale-while-revalidate" and "s-maxage" (it's returned by default by next.js on JSON and html pages)
// Fastly, our CDN is configured to override cache-control value to same as const below `defaultSurrogateCacheForPages`
// Same time, Fastly will respect Surrogate-Control headers wih priority, which is syncronized with the override values
// To skip Fastly overrides, don't use combination of "stale-while-revalidate" and "s-maxage" cache headers and override `Surrogate-Control` headers

// Semantic search requests are cached for 10 minutes on CDN and 1 minute on browser
export const defaultCacheForSearchPages = `public, max-age=60, s-maxage=600, stale-while-revalidate=86400, stale-if-error=86400`;

// skip browser cache
// and if you don't set Surrogate cache control - it will default to only using Surrogate-Control headers for CDN (see next.config.js)
export const cacheOnlyOnCdn = `public, no-cache, max-age=0`;

export const preventCachingBothBrowserAndCdn = `private, no-cache, no-store, max-age=0, must-revalidate`;

// these default headers are set via next.config.js for all HTML and JSON pages by default, but can be overridden in getServerSideProps
// same set of headers are being set on Fastly level automatically to all pages that considered to be public for caching, as next.js does not allow rewriting this on build step
// Surrogate-Control is like a Cache-Control header that Fastly uses it with priority (important to set correct TTLs)
// max-age acts as s-maxage
export const defaultSurrogateCacheForPages = `max-age=600, stale-while-revalidate=14400, stale-if-error=14400`;

// For using in responses that does not have default Surrogate-Control headers applied (like xml sitemaps and other non HTML/JSON routes that needs full caching)
export const fullSetOfCacheHeaders = `public, max-age=60, s-maxage=600, stale-while-revalidate=14400, stale-if-error=14400`;

export const vimeoAccessToken = 'f44af8639ba5c1f807276b1899bd859e';
