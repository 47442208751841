export function formatDate(date: Date | null): string {
    if (!date) {
        return '';
    }
    return (
        date.toLocaleDateString('en-US', { day: '2-digit' }) +
        ' ' +
        date.toLocaleDateString('en-US', { month: 'short' }) +
        ', ' +
        date.toLocaleDateString('en-US', { year: 'numeric' })
    );
}
