import Link from 'next/link';
import { Tag } from '@gn/db';
import { NewContentCountNotification } from '../NewContentCountNotification';
import React from 'react';

type SimpleTag = Pick<Tag, 'id' | 'slug' | 'label'>;

type TagComponentProps = {
    tag: SimpleTag;
    selectedTag?: string | null;
    prefetch?: boolean;
    userNewContentTags?: { [key: number]: number };
    deleteTag?: (arg0: SimpleTag) => void;
    openLinksInNewTab?: boolean;
};

export function TagComponent({ tag, selectedTag, prefetch, userNewContentTags, openLinksInNewTab }: TagComponentProps) {
    return (
        <Link
            href={`/tags/${tag.slug}`}
            prefetch={typeof prefetch === 'undefined' ? false : prefetch === false ? false : undefined}
            className={`tag ${selectedTag === tag.slug ? 'active' : ''}`}
            target={openLinksInNewTab ? '_blank' : undefined}
        >
            {tag.label}
            <NewContentCountNotification contentCountsByType={userNewContentTags} contentType={tag.id} />
        </Link>
    );
}

export function RemovableTagComponent({ tag, selectedTag, userNewContentTags, deleteTag }: TagComponentProps) {
    return (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault();
                deleteTag && deleteTag(tag);
            }}
            className={`tag ${selectedTag === tag.slug ? 'active' : ''}`}
        >
            {tag.label}
            <NewContentCountNotification contentCountsByType={userNewContentTags} contentType={tag.id} />
        </a>
    );
}
