type IconProp = {
    isBookmark?: boolean;
};

export const BookmarkIcon = ({ isBookmark }: IconProp) => {
    const style = isBookmark ? { stroke: '#FF6600', fill: '#FF6600' } : {};
    return (
        <svg className="icon icon-star" style={style}>
            <use xlinkHref="/img/sprite.svg?v3#star" />
        </svg>
    );
};

export const BookmarkIconForCard = () => (
    <svg className="icon icon-star">
        <use xlinkHref="/img/sprite.svg?v3#star" />
    </svg>
);
