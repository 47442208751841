interface RecordingIconProps {
    className?: string;
}

export default function RecordingIcon({ className = 'icon icon-recording' }: RecordingIconProps) {
    return (
        <svg
            className={className}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5.4375 5C5.19505 5 5 5.14629 5 5.32812C5 5.50996 5.19505 5.65625 5.4375 5.65625H5.58333V5.91602C5.58333 6.46699 5.875 6.99609 6.39453 7.38574L7.88203 8.5L6.39453 9.61426C5.875 10.0039 5.58333 10.533 5.58333 11.084V11.3437H5.4375C5.19505 11.3437 5 11.49 5 11.6719C5 11.8537 5.19505 12 5.4375 12H11.5625C11.8049 12 12 11.8537 12 11.6719C12 11.49 11.8049 11.3437 11.5625 11.3437H11.4167V11.084C11.4167 10.533 11.125 10.0039 10.6055 9.61426L9.11797 8.5L10.6036 7.38574C11.125 6.99609 11.4167 6.46699 11.4167 5.91602V5.65625H11.5625C11.8049 5.65625 12 5.50996 12 5.32812C12 5.14629 11.8049 5 11.5625 5H5.4375ZM8.5 8.96348L9.98568 10.0777C10.3411 10.3457 10.5417 10.7066 10.5417 11.084V11.3437H6.45833V11.084C6.45833 10.7066 6.65885 10.3457 7.01432 10.0791L8.5 8.96348ZM8.5 8.03516L7.01432 6.9209C6.65885 6.6543 6.45833 6.29336 6.45833 5.91602V5.65625H10.5417V5.91602C10.5417 6.29336 10.3411 6.6543 9.98568 6.9209L8.5 8.03516Z" />
            <path
                d="M1 3V5.6875H1.54517M15.942 7.5625C15.4806 3.86283 12.3246 1 8.5 1C5.35246 1 2.65777 2.93891 1.54517 5.6875M1.54517 5.6875H4M16 14V11.3125H15.4548M15.4548 11.3125C14.3422 14.0611 11.6475 16 8.5 16C4.67536 16 1.51937 13.1372 1.05803 9.4375M15.4548 11.3125H13"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </svg>
    );
}
