import { renderToString } from 'react-dom/server';
import { User } from '@gn/db';
const color = '#ff7600';

/* eslint-disable */
const faces = [
    <g id="id_1">
        <path
            d="M35.6,66.3c-3.3,0-6-2.7-6-6c0-0.1,0-0.2,0-0.3v-0.5h-7V60c0,7,6,12.6,13,12.6s13-5.6,13-12.6v-0.5h-7V60
		c0.1,3.3-2.4,6.1-5.7,6.2C35.8,66.3,35.7,66.3,35.6,66.3L35.6,66.3z"
            fill={color}
        />
        <path d="M32.6,38.6l-18-0.1l0-6l18,0.1L32.6,38.6z" fill={color} />
        <path d="M56.6,38.5l-18,0.1l0-6l18-0.1L56.6,38.5z" fill={color} />
    </g>,
    <g id="id_2">
        <path
            d="M35.6,66.3c-3.3,0-6-2.7-6-6c0-0.1,0-0.2,0-0.3v-0.5h-7v0.2c0,4.7,2.5,9.1,6.6,11.3c9.3,4.9,19.4-2,19.4-11v-0.5h-7V60
		c0.1,3.3-2.4,6.1-5.7,6.2C35.8,66.3,35.7,66.3,35.6,66.3L35.6,66.3z"
            fill={color}
        />
        <path d="M55.6,38.4l-17,0.1l0-6l17-0.1L55.6,38.4z" fill={color} />
        <path
            id="_x3C_Path_x3E_"
            d="M31.8,32.2c-1.3-3.4-4.7-5.5-8.4-5.5l0,0c-5.1,0-9.2,4.2-9.1,9.4c0.1,4.7,4.1,8.6,8.8,8.8
		C29.4,45.1,34.3,38.8,31.8,32.2z M23.3,38.6L23.3,38.6c-1.6,0-2.9-1.3-2.9-2.9l0,0c0-1.8,1.6-3.2,3.4-2.9c1.2,0.2,2.1,1.2,2.3,2.3
		C26.5,37,25.1,38.6,23.3,38.6z"
            fill={color}
        />
    </g>,
    <g id="id_4">
        <path d="M26.6,60.5h18v6h-18C26.6,66.5,26.6,60.5,26.6,60.5z" fill={color} />
        <path
            id="_x3C_Path_x3E__1_"
            d="M47.1,26.7L47.1,26.7c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1
		C56.2,30.7,52.1,26.7,47.1,26.7z M47.1,38.6L47.1,38.6c-1.6,0-2.9-1.3-2.9-2.9l0,0c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
		S48.7,38.6,47.1,38.6z"
            fill={color}
        />
        <path
            id="_x3C_Path_x3E__2_"
            d="M23.4,26.7C23.3,26.7,23.3,26.7,23.4,26.7c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1
		C32.5,30.7,28.4,26.7,23.4,26.7z M23.3,38.6L23.3,38.6c-1.6,0-2.9-1.3-2.9-2.9l0,0c0-1.6,1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9
		C26.2,37.3,24.9,38.6,23.3,38.6z"
            fill={color}
        />
    </g>,
    <g id="id_8">
        <path
            d="M35.6,66.3c-3.3,0-6-2.7-6-6c0-0.1,0-0.2,0-0.3v-0.5h-7V60c0,7,6,12.6,13,12.6s13-5.6,13-12.6v-0.5h-7V60
		c0.1,3.3-2.4,6.1-5.7,6.2C35.8,66.3,35.7,66.3,35.6,66.3L35.6,66.3z"
            fill={color}
        />
        <path
            id="_x3C_Path_x3E__3_"
            d="M47.1,26.7L47.1,26.7c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1
		C56.2,30.7,52.1,26.7,47.1,26.7z M47.1,38.6L47.1,38.6c-1.6,0-2.9-1.3-2.9-2.9l0,0c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
		S48.7,38.6,47.1,38.6z"
            fill={color}
        />
        <path
            id="_x3C_Path_x3E__4_"
            d="M23.4,26.7C23.3,26.7,23.3,26.7,23.4,26.7c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1
		C32.5,30.7,28.4,26.7,23.4,26.7z M23.3,38.6L23.3,38.6c-1.6,0-2.9-1.3-2.9-2.9l0,0c0-1.6,1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9
		C26.2,37.3,24.9,38.6,23.3,38.6z"
            fill={color}
        />
    </g>,
];
/* eslint-enable */

export function getAvatarDataUri(user: User) {
    const idStr = user.id.toString();
    const faceNo = idStr[idStr.length - 1];
    const faceComponent = faces[parseInt(faceNo) % (faces.length - 1)];
    const svgElement = (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 70.4 90.2">
            <path
                id="Background"
                d="M8,19.7c17.5-5.6,24.2-9,27.2-10.7c3,1.7,9.7,5.2,27.2,10.7c0,2,1.1,62.5-27.2,62.5S8,21.7,8,19.7L8,19.7
            L8,19.7z M35.2,90.2c36.6,0.1,35.2-73.8,35.2-76.3C47.7,6.6,39.1,2.2,35.2,0C31.3,2.2,22.7,6.6,0,13.9C0,16.4-1.4,90.3,35.2,90.2
            L35.2,90.2z"
                fill={color}
            />
            {faceComponent}
        </svg>
    );
    const svgStr = typeof window !== 'undefined' && window.btoa(renderToString(svgElement));
    return `data:image/svg+xml;base64,${svgStr}`;
}

export function DefaultAvatar({ user, className }) {
    const idStr = user.id.toString();
    const faceNo = idStr[idStr.length - 1];
    const faceComponent = faces[parseInt(faceNo) % (faces.length - 1)];

    return (
        <div className={className + ' svg'} style={{ position: 'relative' }}>
            <div style={{ padding: '20% 25% 25% 25%' }}>
                <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 70.4 90.2">
                    <path
                        id="Background"
                        d="M8,19.7c17.5-5.6,24.2-9,27.2-10.7c3,1.7,9.7,5.2,27.2,10.7c0,2,1.1,62.5-27.2,62.5S8,21.7,8,19.7L8,19.7
            L8,19.7z M35.2,90.2c36.6,0.1,35.2-73.8,35.2-76.3C47.7,6.6,39.1,2.2,35.2,0C31.3,2.2,22.7,6.6,0,13.9C0,16.4-1.4,90.3,35.2,90.2
            L35.2,90.2z"
                        fill={color}
                    />
                    {faceComponent}
                </svg>
            </div>
        </div>
    );
}
