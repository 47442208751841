import React, { createContext } from 'react';
import { PromiseReturnType } from 'blitz';
import getCurrentUser from 'app/users/queries/getCurrentUser';
import { useCurrentUser } from '../hooks/useCurrentUser';

export type ContextUser = PromiseReturnType<typeof getCurrentUser> | null | undefined;
export const CurrentUserContext = createContext<ContextUser>(undefined);

export let refetchUser: (a?: any) => any;
export let setUser: (a: any, b?: any) => any;

export function CurrentUserProvider({ children, initialData }) {
    return <CurrentUserProviderComponent initialData={initialData}>{children}</CurrentUserProviderComponent>;
}

export function CurrentUserProviderComponent({ children, initialData }) {
    const { user, refetchCurrentUser, setCurrentUser } = useCurrentUser(initialData);
    setUser = setCurrentUser;
    refetchUser = refetchCurrentUser;

    return <CurrentUserContext.Provider value={user}>{children}</CurrentUserContext.Provider>;
}
