export enum Lang {
    EN = 'EN',
    ES = 'ES',
}

export const LANG_TITLES = {
    [Lang.EN]: 'English',
    [Lang.ES]: 'Español',
};

export const LANG_VERSION_TITLES = {
    [Lang.EN]: 'English version',
    [Lang.ES]: 'Versión en Español',
};
