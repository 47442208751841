import { useQuery } from '@blitzjs/rpc';
import getCurrentUser from 'app/users/queries/getCurrentUser';
import { useSession } from '@blitzjs/auth';

export let refetchCurrentUser = (any?) => any;
export let setCurrentUser = (any?) => any;

export const useCurrentUser = (initialData) => {
    const session = useSession();
    const [user, queryExtras] = useQuery(getCurrentUser, null, { initialData, enabled: !!session.userId });

    refetchCurrentUser = queryExtras.refetch;
    setCurrentUser = queryExtras.setQueryData;
    return {
        user,
        refetchCurrentUser,
        setCurrentUser,
    };
};
