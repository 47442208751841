import type { Subscription } from '@gn/db/client';
import { MultipassUser, Role, SubscriptionStatus } from '../type';

export function isSubscriptionActive(
    {
        currentTermEnd,
        currentTermStart,
        status,
        itemTrialEnd,
    }: Pick<Subscription, 'status' | 'currentTermEnd' | 'currentTermStart' | 'itemTrialEnd'>,
    date: Date,
) {
    switch (status) {
        case SubscriptionStatus.Cancelled:
        case SubscriptionStatus.Paused:
            return false;
        case SubscriptionStatus.Active:
            return currentTermStart && currentTermStart < date;
        case SubscriptionStatus.NonRenewing:
            return currentTermEnd && currentTermStart && currentTermStart < date && currentTermEnd > date;
        case SubscriptionStatus.Trial:
            return itemTrialEnd && itemTrialEnd > date;
        default:
            return false;
    }
}

export function isMultipassValid(user: MultipassUser, date = new Date()): boolean {
    const fixedMultipassValidity = user?.multipassValidUntil;

    if (!!user.role && (user.role === Role.User || user.role === Role.CoreTeam)) {
        return true;
    }

    // TODO get date from server, when code runs on client side
    return (
        (fixedMultipassValidity && fixedMultipassValidity >= date) ||
        (!!user.subscriptions && user.subscriptions.some((subscription) => isSubscriptionActive(subscription, date)))
    );
}

export function isFixedMultipass(user: MultipassUser) {
    if (!!user.role && (user.role === Role.User || user.role === Role.CoreTeam)) {
        return false;
    }

    const today = new Date();
    const hasActiveSubscription =
        user.subscriptions && user.subscriptions.some((sub) => isSubscriptionActive(sub, today));
    return !hasActiveSubscription && !!user.multipassValidUntil;
}
